import Backoffice from "@snark/backoffice"
import {formatPictures, formatStorageSize} from "@/helpers/formatters";

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	account: Backoffice.populator("account", {
	}),
};

export default {
	name: "project",
	collection: "project",
	idProperty: "_id",
	section: "app",
	sort: {"back":[{"field":"account.name"},{"field":"createdAt"}]},
	pagination: 50,
	backPopulateList: ["account"],
	labels: {"singleTitle":"Project","listTitle":"Projects"},
	emptyItem: {"tags":[],"pictures":[],"storageSize":0,"members":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "accountId",
			label: "Account Id",
			formatter: Backoffice.formatters.linkObjectId("account", {idProperty: "_id","label": context.account.formatter()}),
			editor: context.account.editor(),
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "description",
			label: "Description",
			formatter: Backoffice.formatters.multiLines(),
			editor: {"type":"text","lines":5},
		},
		{
			name: "type",
			label: "Type",
		},
		{
			name: "deadline",
			label: "Deadline",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "budget",
			label: "Budget",
			formatter: Backoffice.formatters.price(),
			editor: {type: "float", mode: "price"},
		},
		{
			name: "tags",
			label: "Tags",
			formatter: Backoffice.formatters.tags(undefined, ""),
			editor: {"type":"tags","valueFormat":"array"},
			list: true,
		},
		{
			name: "pictures",
			label: "Pictures",
			readOnly: true,
			formatter: formatPictures,
			list: true,
			children: [
				{
					name: "id",
					label: "Id",
				},
				{
					name: "type",
					label: "Type",
				},
				{
					name: "favorite",
					label: "Favorite",
					formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
					editor: {type: "boolean", labels: ["Non", "Oui"]},
				},
				{
					name: "comments",
					label: "Comments",
				},
			],
		},
		{
			name: "storageSize",
			label: "Storage Size",
			readOnly: true,
			formatter: formatStorageSize,
		},
		{
			name: "demo",
			label: "Demo",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "purchases",
			label: "Purchases",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "members",
			label: "Members",
			list: true,
			children: [
				{
					name: "userId",
					label: "User Id",
				},
				{
					name: "role",
					label: "role",
					formatter: Backoffice.formatters.mapping({"contributor": "Contributor", "visitor": "Visitor"}),
					editor: {type: "select", options: [{value: "contributor", label: "Contributor"}, {value: "visitor", label: "Visitor"}]},
				},
				{
					name: "since",
					label: "Since",
					formatter: Backoffice.formatters.date("DD/MM/YYYY"),
					editor: {type: "date"},
				},
				{
					name: "name",
					label: "Name",
				},
			],
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "account",
			label: "Account",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "storageSize",
			label: "Storage Size",
			readOnly: true,
			formatter: formatStorageSize,
		},
		{
			name: "demo",
			label: "Demo",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
		},
	],
	loadSingle() {
		return Promise.all([
			context.account.load(),
		]);
	},
};