import Backoffice from "@snark/backoffice"
import {formatStorageSize} from "@/helpers/formatters";

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "subscription",
	collection: "subscription",
	idProperty: "_id",
	section: "finance",
	sort: {"back":[{"field":"active","order":"desc"},{"field":"startAt"}]},
	pagination: 50,
	backPopulateList: ["account"],
	backTextFilterList: true,
	labels: {"singleTitle":"Subscription","listTitle":"Subscriptions"},
	emptyItem: {"level":"free","maxStorage":0,"usedStorage":0,"maxMembers":1,"active":true,"price":0},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "accountId",
			label: "Account Id",
			readOnly: true,
		},
		{
			name: "level",
			label: "Level",
			formatter: Backoffice.formatters.mapping({"notChoosen": "Not Choosen", "free": "Free", "solo": "Solo", "business": "Business", "custom": "Custom"}),
			editor: {type: "select", options: [{value: "notChoosen", label: "Not Choosen"}, {value: "free", label: "Free"}, {value: "solo", label: "Solo"}, {value: "business", label: "Business"}, {value: "custom", label: "Custom"}]},
		},
		{
			name: "startAt",
			label: "Start At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "endAt",
			label: "End At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "maxStorage",
			label: "Max Storage",
			readOnly: true,
			formatter: formatStorageSize,
		},
		{
			name: "usedStorage",
			label: "Used Storage",
			readOnly: true,
			formatter: formatStorageSize,
		},
		{
			name: "newMaxStorage",
			label: "New Max Storage",
			editor: {"type":"float","min":0},
			hideOnCreate: true,
			writeOnly: true,
		},
		{
			name: "newUsedStorage",
			label: "New Used Storage",
			editor: {"type":"float","min":0},
			hideOnCreate: true,
			writeOnly: true,
		},
		{
			name: "maxMembers",
			label: "Max Members",
			editor: {"type":"integer","min":1},
		},
		{
			name: "active",
			label: "Active",
			formatter: Backoffice.formatters.boolean(["-", "Actif"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "price",
			label: "Price",
			formatter: Backoffice.formatters.price(),
			editor: {type: "float", mode: "price"},
		},
		{
			name: "recurenceEnded",
			label: "Recurence Ended",
			formatter: Backoffice.formatters.boolean(["Renouvelé", "Non renouvelé"]),
			editor: {"type":"boolean","labels":["Renouvelé","Non renouvelé"]},
		},
		{
			name: "future",
			label: "Future",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "stripeId",
			label: "Stripe Id",
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "account",
			label: "Account",
			formatter: {"linkObjectId":"account","idProperty":"_id","label":"Backoffice.formatters.subProperty('name')"},
			nosingle: true,
		},
		{
			name: "level",
			label: "Level",
			formatter: Backoffice.formatters.mapping({"notChoosen": "Not Choosen", "free": "Free", "solo": "Solo", "business": "Business", "custom": "Custom"}),
		},
		{
			name: "startAt",
			label: "Start At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "endAt",
			label: "End At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "active",
			label: "Active",
			formatter: Backoffice.formatters.boolean(["-", "Actif"]),
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                if (item.newMaxStorage && item.newMaxStorage > 0) {
                    item.maxStorage = Math.max(item.newMaxStorage * 1024 * 1024 * 1024, item.usedStorage);
                    delete item.newMaxStorage;
                }
                if (item.newUsedStorage && item.newUsedStorage > 0) {
                    item.usedStorage = Math.max(item.newUsedStorage * 1024 * 1024 * 1024, item.usedStorage);
                    delete item.newUsedStorage;
                }
                return item;
            });
        },
};