import Vue from "vue";
import Backoffice from "@snark/backoffice";

import crudAccount from "./crud/account";
import crudAdvice from "./crud/advice";
import crudBouser from "./crud/bouser";
import crudInvitation from "./crud/invitation";
import crudInvoice from "./crud/invoice";
import crudNotification from "./crud/notification";
import crudPicture from "./crud/picture";
import crudProject from "./crud/project";
import crudProjectType from "./crud/project_type";
import crudSearch from "./crud/search";
import crudSubscription from "./crud/subscription";
import crudUser from "./crud/user";

import moment from "moment-timezone";
import axios from "axios";
moment.tz.setDefault('Europe/Paris');
moment.locale('fr');

Vue.config.productionTip = false;

Vue.use(Backoffice, {
    title: "Spriiks - BO",
    debug: true,
    apiUrl: process.env.VUE_APP_API_URL,
    apiVersion: 2,
    gmapApiKey: process.env.VUE_APP_GMAP_KEY,
    logAs: {
        token: process.env.VUE_APP_LOGAS_TOKEN,
        frontUrl: process.env.VUE_APP_LOGAS_FRONT_URL,
        apiRoute: process.env.VUE_APP_LOGAS_API_ROUTE
    },
    authenticate: async function (login, password) {
        try {
            const result = await axios.post(process.env.VUE_APP_API_AUTHENTICATE_URL, {
                username: login,
                password: password
            });
            if (result.data.token) {
                return {token: result.data.token};
            }
        }
        catch(err) {
            console.log(err);
        }
        throw "unauthorized";
    },
    crud: async function () {
        // app
        Vue.crud(crudUser);
        Vue.crud(crudAccount);
        Vue.crud(crudProject);
        Vue.crud(crudPicture);
        Vue.crud(crudInvitation);
        // data
        Vue.crud(crudAdvice);
        Vue.crud(crudProjectType);
        Vue.crud(crudSearch);
        Vue.crud(crudNotification);
        // finance
        Vue.crud(crudSubscription);
        Vue.crud(crudInvoice);
        // backoffice
        Vue.crud(crudBouser);
    },
    defaultCRUDOptions: {
        editor: {
            select: {
                sort: 'asc'
            },
            checks: {
                sort: 'asc'
            }
        }
    },
    //routes: {
    //    prepare: function (routes) {
    //    }
    //},
    menuSections: ["app", "data", "finance", "backoffice", "operation"],
    dashboardBlocks: []
});

Vue.backoffice.start(Vue);
