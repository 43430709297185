import Backoffice from "@snark/backoffice"
import {formatStorageSize} from "@/helpers/formatters";

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	account: Backoffice.populator("account", {
	}),
	user: Backoffice.populator("user", {
		displayProperty: item => {
                    return item.firstname + " " + item.lastname;
                },
	}),
};

export default {
	name: "picture",
	collection: "picture",
	idProperty: "_id",
	section: "app",
	sort: {"back":[{"field":"createdAt"}]},
	pagination: 30,
	backPopulateList: ["account","user"],
	labels: {"singleTitle":"Picture","listTitle":"Pictures"},
	emptyItem: {"keywords":[],"colors":[],"colorType":"rgb"},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "accountId",
			label: "Account Id",
		},
		{
			name: "contributorId",
			label: "Contributor Id",
		},
		{
			name: "path",
			label: "Path",
			readOnly: true,
			formatter: Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL,maxHeight: 300}),
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "legend",
			label: "Legend",
			formatter: Backoffice.formatters.multiLines(),
			editor: {"type":"text","lines":5},
		},
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "localisation",
			label: "Localisation",
		},
		{
			name: "author",
			label: "Author",
		},
		{
			name: "keywords",
			label: "Keywords",
			formatter: Backoffice.formatters.tags(undefined, ""),
			editor: {"type":"tags","valueFormat":"array"},
			list: true,
		},
		{
			name: "colors",
			label: "Colors",
			formatter: Backoffice.formatters.tags(undefined, ""),
			editor: {"type":"tags","valueFormat":"array"},
			list: true,
		},
		{
			name: "dimensions",
			label: "Dimensions",
		},
		{
			name: "size",
			label: "Size",
			formatter: formatStorageSize,
			editor: {type: "float"},
		},
		{
			name: "dpi",
			label: "Dpi",
			editor: {type: "float"},
		},
		{
			name: "colorType",
			label: "Color Type",
			formatter: Backoffice.formatters.mapping({"cmyk": "Cmyk", "rgb": "Rgb", "bw": "Bw"}),
			editor: {type: "select", options: [{value: "cmyk", label: "Cmyk"}, {value: "rgb", label: "Rgb"}, {value: "bw", label: "Bw"}]},
		},
		{
			name: "mimetype",
			label: "Mimetype",
		},
		{
			name: "comments",
			label: "Comments",
		},
		{
			name: "purchasable",
			label: "Purchasable",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "price",
			label: "Price",
			formatter: Backoffice.formatters.price(),
			editor: {type: "float", mode: "price"},
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "account",
			label: "Account",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "path",
			label: "Path",
			readOnly: true,
			formatter: Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL,maxHeight: 80}),
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "size",
			label: "Size",
			formatter: formatStorageSize,
		},
	],
	loadSingle() {
		return Promise.all([
			context.account.load(),
			context.user.load(),
		]);
	},
};