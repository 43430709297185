import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "notification",
	collection: "notification",
	idProperty: "_id",
	section: "operation",
	labels: {"singleTitle":"Notification","listTitle":"Notifications"},
	emptyItem: {"data":null},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "title",
			label: "Title",
		},
		{
			name: "text",
			label: "Text",
		},
		{
			name: "link",
			label: "Link",
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"userForgotPassword": "User Forgot Password", "welcome": "Welcome", "userAskPersonalData": "User Ask Personal Data", "contactFromSite": "Contact From Site", "firstProject": "First Project"}),
			editor: {type: "select", options: [{value: "userForgotPassword", label: "User Forgot Password"}, {value: "welcome", label: "Welcome"}, {value: "userAskPersonalData", label: "User Ask Personal Data"}, {value: "contactFromSite", label: "Contact From Site"}, {value: "firstProject", label: "First Project"}]},
		},
		{
			name: "items",
			label: "Items",
		},
		{
			name: "data",
			label: "Data",
		},
		{
			name: "level",
			label: "Level",
			formatter: Backoffice.formatters.mapping({"info": "Info", "warning": "Warning", "alert": "Alert"}),
			editor: {type: "select", options: [{value: "info", label: "Info"}, {value: "warning", label: "Warning"}, {value: "alert", label: "Alert"}]},
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"unread": "Unread", "read": "Read", "terminated": "Terminated", "archived": "Archived"}),
			editor: {type: "select", options: [{value: "unread", label: "Unread"}, {value: "read", label: "Read"}, {value: "terminated", label: "Terminated"}, {value: "archived", label: "Archived"}]},
		},
		{
			name: "terminationTimestamp",
			label: "Termination Timestamp",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "title",
			label: "Title",
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"userForgotPassword": "User Forgot Password", "welcome": "Welcome", "userAskPersonalData": "User Ask Personal Data", "contactFromSite": "Contact From Site", "firstProject": "First Project"}),
		},
		{
			name: "level",
			label: "Level",
			formatter: Backoffice.formatters.mapping({"info": "Info", "warning": "Warning", "alert": "Alert"}),
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"unread": "Unread", "read": "Read", "terminated": "Terminated", "archived": "Archived"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
};