import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "user",
	collection: "user",
	idProperty: "_id",
	section: "app",
	sort: {"back":[{"field":"lastname"},{"field":"firstname"}]},
	pagination: 50,
	backTextFilterList: true,
	viewActions: [
		{
			type: "logAs",
			logAs: {"field":"email"},
		},
	],
	labels: {"singleTitle":"User","listTitle":"Users"},
	emptyItem: {"interests":[],"favoritePictures":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "email",
			label: "Email",
		},
		{
			name: "password",
			label: "Password",
			writeOnly: true,
		},
		{
			name: "firstname",
			label: "Firstname",
		},
		{
			name: "lastname",
			label: "Lastname",
		},
		{
			name: "picture",
			label: "Picture",
		},
		{
			name: "interests",
			label: "Interests",
			list: true,
		},
		{
			name: "cgvAccepted",
			label: "Cgv Accepted",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "marketingAccepted",
			label: "Marketing Accepted",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "lastProjectId",
			label: "Last Project Id",
		},
		{
			name: "favoritePictures",
			label: "Favorite Pictures",
			list: true,
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "token",
			label: "Token",
			readOnly: true,
		},
		{
			name: "tokenExpire",
			label: "Token Expire",
			readOnly: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "resetToken",
			label: "Reset Token",
			readOnly: true,
		},
		{
			name: "resetTokenExpire",
			label: "Reset Token Expire",
			readOnly: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "email",
			label: "Email",
		},
		{
			name: "firstname",
			label: "Firstname",
		},
		{
			name: "lastname",
			label: "Lastname",
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
};