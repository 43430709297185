import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "invoice",
	collection: "invoice",
	idProperty: "_id",
	section: "finance",
	backPopulateList: ["account"],
	labels: {"singleTitle":"Invoice","listTitle":"Invoices"},
	emptyItem: {"lines":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "accountId",
			label: "Account Id",
		},
		{
			name: "reference",
			label: "Reference",
		},
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "amount",
			label: "Amount",
		},
		{
			name: "lines",
			label: "Lines",
			list: true,
			children: [
			],
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "account",
			label: "Account",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "reference",
			label: "Reference",
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
};