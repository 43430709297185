import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "advice",
	collection: "advice",
	idProperty: "_id",
	section: "data",
	sort: {"back":[{"field":"rank"},{"field":"title"}]},
	labels: {"singleTitle":"Advice","listTitle":"Advices"},
	emptyItem: {"rank":0},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "title",
			label: "Title",
		},
		{
			name: "icon",
			label: "Icon",
			editor: {
				type: "image",
				preview: {
					urlPrefix: undefined,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
		},
		{
			name: "url",
			label: "Url",
		},
		{
			name: "rank",
			label: "Rank",
			editor: {"type":"integer","width":"100px"},
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "title",
			label: "Title",
		},
		{
			name: "icon",
			label: "Icon",
		},
		{
			name: "url",
			label: "Url",
		},
		{
			name: "rank",
			label: "Rank",
		},
	],
};