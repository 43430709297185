export function goFormatter(field, item) {
	const val = item[field.name];
	if (val && val > 0) {
		return (val / 1024 / 1024 / 1024).toFixed(1);
	} else {
		return "-";
	}
}

export function formatStorageSize(field, item) {
	const bytes = item[field.name];
	if (!!bytes && bytes > 0) {
		let suffix;
		let value = bytes / 1024;
		if (value > 1000) {
			value /= 1024;
			if (value > 1000) {
				value /= 1024;
				if (value > 1000) {
					value /= 1024;
					suffix = "To";
				} else {
					suffix = "Go";
				}
			} else {
				suffix = "Mo";
			}
		} else {
			suffix = "Ko";
		}
		return value.toFixed(1).replace(/\./g, ",") + " " + suffix;
	} else {
		return "0";
	}
}

export function formatPictures(field, item) {
	const baseUrl = process.env.VUE_APP_API_STATIC_URL + "project/" + item._id + "/";
	const end = "-ld.jpg";
	return item.pictures ? item.pictures
			.map(pic => {
				return `<a href="#/picture/view/${pic.id}"><img src=${baseUrl + pic.id + end} style="display:inline-block; width: 100px; height: 100px; object-fit: cover; margin: 10px" crossorigin="anonymous" alt="picture"/></a>`;
			})
			.join("\n")
		: "-";
}