import Backoffice from "@snark/backoffice"
import {formatStorageSize} from "@/helpers/formatters";

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "account",
	collection: "account",
	idProperty: "_id",
	section: "app",
	sort: {"back":[{"field":"name"}]},
	pagination: 50,
	backTextFilterList: true,
	labels: {"singleTitle":"Account","listTitle":"Accounts"},
	emptyItem: {"subscriptionLevel":"notChoosen","maxStorage":0,"usedStorage":0,"maxMembers":1,"members":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "subscriptionLevel",
			label: "Abonnement",
			formatter: Backoffice.formatters.mapping({"notChoosen": "Not Choosen", "free": "Free", "solo": "Solo", "business": "Business", "custom": "Custom"}),
			editor: {type: "select", options: [{value: "notChoosen", label: "Not Choosen"}, {value: "free", label: "Free"}, {value: "solo", label: "Solo"}, {value: "business", label: "Business"}, {value: "custom", label: "Custom"}]},
		},
		{
			name: "maxStorage",
			label: "Max Storage",
			formatter: formatStorageSize,
			readOnly: true,
		},
		{
			name: "usedStorage",
			label: "Used Storage",
			readOnly: true,
			formatter: formatStorageSize,
		},
		{
			name: "newMaxStorage",
			label: "Nouveau Stockage Max (en Go)",
			editor: {"type":"float","min":0},
			hideOnCreate: true,
			writeOnly: true,
		},
		{
			name: "maxMembers",
			label: "Max Members",
			editor: {"type":"integer","min":1},
		},
		{
			name: "spriiks",
			label: "Spriiks",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "stripeId",
			label: "Stripe Id",
			readOnly: true,
		},
		{
			name: "members",
			label: "Members",
			list: true,
			children: [
				{
					name: "userId",
					label: "User Id",
				},
				{
					name: "role",
					label: "role",
					formatter: Backoffice.formatters.mapping({"owner": "Owner", "member": "Member"}),
					editor: {type: "select", options: [{value: "owner", label: "Owner"}, {value: "member", label: "Member"}]},
				},
				{
					name: "since",
					label: "Since",
					formatter: Backoffice.formatters.date("DD/MM/YYYY"),
					editor: {type: "date"},
				},
,
			],
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "name",
			label: "Name",
		},
		{
			name: "subscriptionLevel",
			label: "Abonnement",
			formatter: Backoffice.formatters.mapping({"notChoosen": "Not Choosen", "free": "Free", "solo": "Solo", "business": "Business", "custom": "Custom"}),
		},
		{
			name: "usedStorage",
			label: "Used Storage",
			readOnly: true,
			formatter: function (field, item) {
                return item.maxStorage > 0 ? Math.round(item.usedStorage / item.maxStorage * 100) + "%" : "-";
            },
		},
		{
			name: "spriiks",
			label: "Spriiks",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                if (item.newMaxStorage && item.newMaxStorage > 0) {
                    item.maxStorage = Math.max(item.newMaxStorage * 1024 * 1024 * 1024, item.usedStorage);
                    delete item.newMaxStorage;
                }
                return item;
            });
        },
};